/* eslint-disable react-hooks/exhaustive-deps */
import { React, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { customGet } from "../../../utils/services";
import BreadCrumb from "../../../components/common/BreadCrumb";
import CustomTable from "../../../components/common/CustomTable";
import Spinner from "../../../components/common/Spinner";

const tableColumns = [
    { title: "id", value: "id", tipo: "text" },
    { title: "Usuario", value: "usuario.name", tipo: "text" },
    { title: "Observaciones", value: "observaciones", tipo: "text" },
    { title: "Fecha Inicial", value: "fecha_inicial", tipo: "datetime" },
    { title: "Fecha Final", value: "fecha_final", tipo: "datetime" }
];
const {
    REACT_APP_API_URL
} = process.env;

export const TableCortesHotel = () => {
    const token = useSelector((state) => state.loginReducer.data.token);
    const [urlFetch, setUrlFech] = useState(undefined);
    const [dataTable, setDataTable] = useState([]);
    const [tableLoading, setTableLoading] = useState(false);
    const [order, setOrder] = useState("");
    const permiso = "hotel-ingreso-corte";

    const handleVer = (registro) => {
        console.log(registro);
        window.open(`${REACT_APP_API_URL}/imprimir-corte/${registro.id}`, "_blank")
    }

    useEffect(() => {
        setTableLoading(true);
        customGet(urlFetch, `cortes/listar/hotel`, token).then((data) => {
            setDataTable(data);
            setTableLoading(false);
        });
    }, [urlFetch]);

    const pages = [
        { name: "Listado de Cortes - Hotel", href: "/", current: false },
    ];

    return (
        <div>
            <div className="flex flex-row justify-between items-end sm:flex mx-5 mt-2">
                <BreadCrumb navegacion={pages} />
                <div className="flex flex-row">
                </div>
            </div>
            <div className="shadow border border-gray-300 rounded-lg overflow-x-auto mx-5 mt-2">
                <CustomTable
                    dataTable={dataTable}
                    tableColumns={tableColumns}
                    fetchPage={setUrlFech}
                    pagination={true}
                    showVer={true}
                    handleVer={handleVer}
                    showEditar={false}
                    showEliminar={false}
                    setOrder={setOrder}
                    order={order}
                    permiso={permiso}
                />
                {tableLoading && (
                    <div className="mt-10 mb-10">
                        <Spinner />
                    </div>
                )}
            </div>
        </div>
    );
};
